import React ,{useState}from 'react';
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
// @ts-ignore
import classes from './styled.module.scss';

interface CustomSelectProps {
  unitsData: { value: string; label: string; items: string[] }[];
  onAddMetric: () => void;
  selectedValue: string[];
  onValueChange: (value: string[]) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  unitsData,
  onAddMetric,
  selectedValue,
  onValueChange,
}) => {
  const { planDetails } = useSelector((state: any) => state.plan.plan);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const transformedItems = unitsData.map((unit) => ({
    key: unit.value,
    label: unit.label,
    children: unit.items.map((item) => ({
      key: item,
      label: item,
      onClick: () => {
        onValueChange([item]);
        setIsDropdownOpen(false); 
      },
    })),
  }));

  const transformedLabels = planDetails?.goal?.custom_labels?.map((label: any) => ({
    key: label.id,
    label: label.value,
    children: label.custom_unit_options.map((item: any) => ({
      key: item.id,
      label: item.value,
      onClick: () => onValueChange([item.value]),
    })),
  })) || [];

  const menuItems = [
    ...transformedItems,
    transformedLabels.length > 0 && {
      key: 'customMetricsHeading',
      
      label: (
        <div style={{display:"flex"}}>

        <span style={{ color: '#1271A6', fontSize: 10, fontWeight: 'bold', cursor: 'default',textAlign:"end" ,width:"100%"}}>
          Custom Metrics
        </span>
        </div>
      ),
      type: 'group',
      
    },
    ...transformedLabels.map((unit: any) => ({
      key: unit.key,
      label: unit.label,
      children: unit.children,
    })),
  ];
  const handleAddMetricClick = (event: React.MouseEvent) => {
    event.stopPropagation(); 
    onAddMetric();
    setIsDropdownOpen(false); 
  };
  return (
    <Dropdown
      overlay={<Menu items={menuItems} />}
      trigger={['click']}
      placement="bottomLeft"
      open={isDropdownOpen}
      overlayClassName={classes.customDropdownMenu}
      onOpenChange={(open) => setIsDropdownOpen(open)}
      dropdownRender={(menu) => (
        <>
          <div style={{ height: 200, overflow: "auto" ,width:"130px"}}>

            {menu}
          </div>
          <div style={{
            textAlign: "end", backgroundColor: "#fff",
            borderRadius: "2px",
            outline: "none",
            paddingRight: "5px",
            width: "120px"
          }}>
            <span onClick={handleAddMetricClick} style={{ color: '#1271A6', cursor: 'pointer', textAlign: "right" }}>
              + Add Metric
            </span>
          </div>

        </>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space className={classes.dropdownTrigger}>
          {selectedValue.length > 0 ? selectedValue : '--'} <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};

export default CustomSelect;

