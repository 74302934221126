import { Button, Col, Modal, Row } from "antd";
import React, { useState, useEffect } from "react";
import { ReactComponent as Cross } from "assets/svg/deleteIcon.svg";
import { ReactComponent as ArrowDownGray } from "assets/svg/arrowDownGray.svg";
import { ReactComponent as DeleteBox } from "assets/svg/colorTrash.svg";
import { ReactComponent as LightStar } from "assets/svg/lightStar.svg";

import AppInput from "components/AppInput";
import { PostMetric } from '../../../modules/actions/PlanActions';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
export default function ManageCustomMetricsModal({
    visible,
    setModalOpen,
    goalId,
    project_plan,
}) {
    const [metrics, setMetrics] = useState([{ metric: "", units: [""] }]);
    const [deletedMetrics, setDeletedMetrics] = useState([]);
    const [deletedUnits, setDeletedUnits] = useState([]);
    const [expandedMetricIndex, setExpandedMetricIndex] = useState();
    const [disableBtn, setDisableBtn] = useState(true)
    const [errors, setErrors] = useState([])

    const addMetric = () => {
        const updatedMetrics = [...metrics, { metric: "", units: [""] }];
        setMetrics(updatedMetrics);
        setExpandedMetricIndex(updatedMetrics.length - 1);
    };

    const removeMetric = (index) => {
        const updatedMetrics = metrics.filter((_, i) => i !== index);
        const metricToRemove = metrics[index];

        if (metricToRemove.metricId) {
            setDeletedMetrics([...deletedMetrics, metricToRemove.metricId]);
        }
        setMetrics(updatedMetrics);
        updateDisableBtn(updatedMetrics)
        setExpandedMetricIndex(null);
    };

    const handleMetricChange = (index, value) => {
        const updatedMetrics = metrics.map((metric, i) =>
            i === index ? { ...metric, metric: value } : metric
        );
        setMetrics(updatedMetrics);
        updateDisableBtn(updatedMetrics);
    };

    const addUnit = (metricIndex) => {
        const updatedMetrics = metrics.map((metric, i) => {
            if (i === metricIndex) {
                return { ...metric, units: [...metric.units, ""] };
            }
            return metric;
        });
        setMetrics(updatedMetrics);

    };

    ;
    const handleUnitChange = (metricIndex, unitIndex, value) => {
        const updatedMetrics = metrics.map((metric, i) => {
            if (i === metricIndex) {
                const updatedUnits = metric.units.map((unit, j) =>
                    j === unitIndex ? { ...unit, value } : unit
                );
                return { ...metric, units: updatedUnits };
            }
            return metric;
        });
        setMetrics(updatedMetrics);
        updateDisableBtn(updatedMetrics); // Check if button should be enabled
    };


    const removeUnit = (metricIndex, unitIndex) => {
        const updatedMetrics = metrics.map((metric, i) => {
            if (i === metricIndex) {
                const updatedUnits = metric.units.filter((_, j) => j !== unitIndex);
                const unitToRemove = metric.units[unitIndex];


                if (unitToRemove.id) {
                    setDeletedUnits([...deletedUnits, unitToRemove.id]);
                }

                if (updatedUnits.length === 0) {
                    if (metric.metricId) {
                        setDeletedMetrics([...deletedMetrics, metric.metricId]);
                    }
                    return null;
                }

                return { ...metric, units: updatedUnits };
            }
            return metric;
        }).filter(metric => metric !== null);

        setMetrics(updatedMetrics);
        updateDisableBtn(updatedMetrics)
    };

    const dispatch = useDispatch();
    const handleCloseEditModel = () => {
        setModalOpen(false);
        setDisableBtn(true)
        setDeletedMetrics([])
        setDeletedUnits([])
        setErrors([])
    };

    const { planDetails, loading } = useSelector((state) => state.plan.plan);

    useEffect(() => {

        const initialMetrics =
            planDetails?.goal.custom_labels.map(label => ({
                metric: label.value || "",
                units: label.custom_unit_options.map(option => ({ value: option.value, id: option.id })) || [""],
                metricId: label.id
            }))
            ;

        setMetrics([...initialMetrics, { metric: "", units: [""] }]);



        setExpandedMetricIndex(metrics.length - 1);

    }, [planDetails, visible]);
    const validateForm = () => {
        let formIsValid = true;

        const updatedErrors = metrics.map((metric) => ({
            metricError: metric.metric.trim() === "",
            unitErrors: metric.units.map((unit) => unit == [""]),
        }));

        setErrors(updatedErrors);

        // Check if any errors exist
        updatedErrors.forEach((error) => {
            if (error.metricError || error.unitErrors.includes(true)) {
                formIsValid = false;
            }
        });

        return formIsValid;
    };
    const handleSubmitMetric = () => {
        if (validateForm()) {
            const payload = {
                metrics: metrics
                    .filter(({ metric, units }) => metric && units.some(unit => unit))
                    .map(({ metric, units, metricId }) => ({
                        ...(metricId && { id: metricId }),
                        value: metric,
                        goal: goalId,
                        units: units
                            .filter(unit => unit && unit.value)
                            .map(unit => unit.id ? { id: unit.id, value: unit.value } : unit)
                    })),
                deleted_metrics: deletedMetrics,
                deleted_units: deletedUnits
            };

            dispatch(PostMetric(payload, project_plan, handleCloseEditModel));
        } else {
            console.log("Form is not valid, please fix the errors.");
        }
    };
    const updateDisableBtn = (updatedMetrics) => {
        const hasDeletions = deletedUnits || deletedMetrics


        const hasActiveMetricOrUnit = updatedMetrics.some(({ metric, units }) =>
            metric.trim() !== "" || units.some(unit => unit && unit.value && unit.value.trim() !== "")
        );

        setDisableBtn(!(hasDeletions || hasActiveMetricOrUnit));
    };




    const handleKeyDown = (event) => {
        if (event.key === "Enter" && !disableBtn) {
            handleSubmitMetric();
        }
    };



    return (
        <Modal
            height={400}
            closable={false}
            onCancel={handleCloseEditModel}
            title={false}
            open={visible}
            footer={
                <Row justify="end" style={{ backgroundColor: "#F5F5F5", padding: "10px", margin: -10 }}>
                    <Button
                        style={{
                            backgroundColor: "transparent",
                            color: "#1271A6",
                            border: "1px solid #1271A6",
                            width: "88px",
                        }}
                        onClick={handleCloseEditModel}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={disableBtn || loading}
                        style={{ backgroundColor: "#1271A6", color: "white", width: "88px", opacity: disableBtn ? 0.5 : 1 }}
                        onClick={handleSubmitMetric}
                        onKeyDown={(e) => handleKeyDown(e)}
                    >
                        {loading ? (
                            <Spinner animation="border" size="sm" style={{ color: "white" }} />
                        ) : (
                            "Save"
                        )}
                    </Button>
                </Row>
            }
        >
            <Row justify="space-between" className="mb-2">
                <div className="font-16 text_black text_bold">Manage Custom Metrics</div>
                <Cross onClick={
                    handleCloseEditModel
                } className="c-pointer" />
            </Row>
            <div style={{ maxHeight: "300px", overflowY: "auto", padding: "10px" }}>
                {metrics?.map((metric, metricIndex) => (
                    <div key={metricIndex} style={{ backgroundColor: expandedMetricIndex === metricIndex ? "#F5F5F5" : "transparent", padding: 10, marginBottom: 10 }}>
                        <Row>
                            <Col span={22} className="mb-2 d-flex align-items-center gap-1 cursor-pointer" onClick={() => setExpandedMetricIndex(expandedMetricIndex === metricIndex ? null : metricIndex)}>
                                <ArrowDownGray style={{ transform: expandedMetricIndex === metricIndex ? 'rotate(360deg)' : 'rotate(270deg)', transition: 'transform 0.3s' }} />
                                <h5 style={{ color: expandedMetricIndex === metricIndex ? "#1271A6" : "#1B2A3D", fontSize: 14, margin: 0 }}>{metric.metric || "Metric"}</h5>
                            </Col>
                            <Col span={2} className="d-flex justify-content-end align-items-center mb-2 gap-10">
                                <DeleteBox onClick={() => removeMetric(metricIndex)} className="c-pointer" />
                                <LightStar className="c-pointer" />
                            </Col>
                        </Row>
                        {expandedMetricIndex === metricIndex && (
                            <>
                                <Col span={22}>
                                    <AppInput
                                        label="Metric"
                                        placeholder="Enter Metric Label"
                                        value={metric.metric}
                                        name="metric"
                                        onChange={(name, value) => handleMetricChange(metricIndex, value)}
                                        marginBottom={10}
                                        onKeyDown={(e) => handleKeyDown(e)}

                                    />
                                </Col>
                                {errors[metricIndex]?.metricError && <span style={{ color: 'red', fontSize: '12px', }}>Metric is required</span>}
                            </>

                        )}
                        {expandedMetricIndex === metricIndex && metric.units.map((unit, unitIndex) => (
                            <Row key={unitIndex}>
                                <Col span={22}>
                                    <AppInput
                                        label="Unit"
                                        placeholder="Enter Unit"
                                        value={unit.value || ""}
                                        name="unit"
                                        onChange={(name, value) => handleUnitChange(metricIndex, unitIndex, value)}
                                        marginBottom={10}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                    />
                                    {errors[metricIndex]?.unitErrors[unitIndex] && <span style={{ color: 'red', fontSize: '12px' }}>Unit is required</span>}
                                </Col>
                                <Col span={2} className="d-flex justify-content-end align-items-center">
                                    <DeleteBox onClick={() => removeUnit(metricIndex, unitIndex)} className="c-pointer" />
                                </Col>
                            </Row>
                        ))}
                        {expandedMetricIndex === metricIndex && (
                            <Col span={24} style={{ textAlign: "end" }}>
                                <h5 onClick={() => addUnit(metricIndex)} style={{ color: "#1271A6", fontSize: 14, cursor: "pointer" }}>
                                    <span style={{ fontSize: 18 }}>+ </span> Add Unit
                                </h5>
                            </Col>
                        )}
                    </div>
                ))}
            </div>
            <div style={{ textAlign: "end" }}>
                <h5 onClick={addMetric} style={{ color: "#1271A6", fontSize: 14, cursor: "pointer" }}>
                    <span style={{ fontSize: 18 }}>+ </span> Add Metric
                </h5>
            </div>
        </Modal>
    );
}
