import React, { useEffect, useState } from "react";
import classes from "./AIAssistantModal.module.scss";
import { ReactComponent as AssistantIcon } from "../AIAssistantModal/assistant.svg";
import { ReactComponent as CloseIcon } from "../AIAssistantModal/close.svg";
import FormInput from "components/FormInput/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import {
  GenerateAIDefinition,
  SetAIDefinition,
} from "redux/DecisionMatrix/GenerateAIDefinition/action";
import Skeleton from "../Skeleton/skeleton";
import {
  GenerateFacts,
  SetGenerateFactsNull,
} from "redux/DecisionMatrix/GenerateFacts/action";
import { websocketConnection } from "utils/websocket";
import styled from "styled-components";

const FactsModal = ({
  onClose,
  onSelectedCriteriaChange,
  selectedCriteria,
  criteria,
  handleApply,
  optionCriteria,
  decisionId,
  optionId,
  isEditable = false,
}) => {
  const dispatch = useDispatch();
  const [definition, setDefinition] = useState("");
  const [isFactsLoading, setIsFactsLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [errors, setErrors] = useState();
  const [isTryAgain, setIsTryAgain] = useState(true)
  // const { success } = useSelector((state) => state.generateAIDefinitionReducer);
  // const { success, loading, error } = useSelector(
  //   (state) => state.generateFactsReducer
  // );
  useEffect(() => {
    // websocketConnection.connect();
    websocketConnection.setMessageCallback(handleMessage);

    return () => {
      // websocketConnection.close();
      setResponse("");
      setErrors("");
    };
  }, []);
  // useEffect(() => {
  //   // if (isPurposeLoading || isObjectiveLoading) {
  //   let timer;
  //   // if (websocketConnection) {
  //   timer = setInterval(() => {
  //     websocketConnection.send(
  //       JSON.stringify({
  //         pong: "pong",
  //       })
  //     );
  //   }, 1000);
  //   // }
  //   return () => {
  //     clearInterval(timer);
  //   };
  //   // }
  // }, []);
  const handleMessage = (message) => {
    // Handle the received message
    let res = JSON.parse(message);
    if (res.ping || res.pong) return;
    setResponse(res?.message); // Set the received message as the response
    setErrors(res?.message?.error?.message);
    setIsFactsLoading(false);
  };

  useEffect(() => {
    if (!response) return;
    setDefinition(response?.Facts);
  }, [response]);

  useEffect(() => {
    return () => {
      dispatch(SetAIDefinition(null));
      setDefinition("");
      dispatch(SetGenerateFactsNull(null));
    };
  }, []);

  const handleSuggest = () => {
    if (selectedCriteria?.title?.length > 0) {
      setIsTryAgain(false)
      setErrors("");
      setIsFactsLoading(true);
      websocketConnection.send(
        JSON.stringify({
          endpoint: "generate_facts",
          model_id: optionId,
          title: selectedCriteria?.title,
        })
      );

    }
    // dispatch(GenerateFacts(optionCriteria?.id)); // isme option criteria id bhejoge as an argument
  };

  const getOptions = () => {
    const list = [];
    criteria?.map((item) => {
      if (item?.title?.length > 0) {
        list?.push({ value: item?.id, label: item?.title });
      }
    });
    if (list?.length === 0) {
      list?.push({ value: "", label: "Select Criteria" });
    }
    return list;
  };

  return (
    <>
      <div className={classes.background} />
      <div className={classes.modal}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <div className={classes.heading}>
              <AssistantIcon />
              <span>AI Assistant</span>
              <span className={classes.beta}>Beta</span>
            </div>
            <button
              type="button"
              onClick={onClose}
              className={classes.close}
              title="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className={classes.separator_container}>
            <div className={classes.bg_color}></div>
            <div className={classes.separator}></div>
          </div>
          <div className={classes.body}>
            <p>Facts Suggestion</p>
            <p className={classes.suggestText}>
              Generate facts for your each criteria
            </p>
            <Row gutter={8}>
              <Col span={19}>
                <div className={`${classes.inputField}`}>
                  <FormInput
                    height="44px"
                    name={"selectedCriteria"}
                    error={errors ? errors : null}
                    setFieldValue={onSelectedCriteriaChange}
                    // value={selectedCriteria && selectedCriteria}
                    value={selectedCriteria?.id}
                    type="select"
                    options={getOptions()}
                  />
                </div>
              </Col>
              <Col span={5}>
                <button
                  type="button"
                  disabled={isFactsLoading}
                  className={classes.suggest}
                  onClick={handleSuggest}
                >
                  {isFactsLoading ? "Suggesting" : "Suggest"}
                </button>
              </Col>
            </Row>
            {isFactsLoading ? (
              <SkeletonWrapper>
                <Skeleton />
              </SkeletonWrapper>
            ) : (
              <div className={`${classes.inputField}`}>
                <FormInput
                  disabled={isEditable}
                  style={{ background: "#F4F4F4" }}
                  placeholder={"Enter your description"}
                  type="textarea"
                  className="mt-4"
                  name={"definition"}
                  // onChange={(e) => {
                  //   setDefinition(e.target.value);
                  // }}
                  setFieldValue={(name, value) => setDefinition(value)}
                  value={definition}
                />
              </div>
            )}
          </div>

          <div className={classes.footer}>
            <button
              disabled={isTryAgain}
              type="button"
              className={classes.tryagain}
              onClick={handleSuggest}
              style={{ opacity: isTryAgain ? 0.5 : 1 }}
            >
              Try Again
            </button>
            <button
              type="button"
              disabled={isFactsLoading || definition === ""}
              onClick={() => handleApply(selectedCriteria, definition)}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { FactsModal };

const SkeletonWrapper = styled.div`
  padding: 1rem;
  background-color: #f4f4f4;
`;
