import React, { useState } from "react";

// Styles
import styles from "./styles.module.scss";

// Icons
import { ReactComponent as PlusBlue } from "assets/svg/plusBlue.svg";
import { ReactComponent as Close } from "assets/svg/close.svg";
import { ReactComponent as ArrowDown } from "assets/svg/arrowDownGray.svg";
import { ReactComponent as EditIcon } from "assets/svg/editPancil.svg";
import { ReactComponent as TrashIcon } from "assets/svg/trashIcon.svg";

// Antd
import { Popover, Select } from "antd";

// Utils
import { CriteriaColors } from "../../utils/utility";

const CriteriaTag = ({ criteria_tags, setCriteriaTags }) => {
  const [criteriaPop, setCriteriaPop] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditId, setIsEditId] = useState(null);
  const [isEditData, setIsEditData] = useState({
    id: "",
    tag_name: "",
    color: "",
    weight: "",
    isDefaultID: "",
  });
  const [criteriaInput, setCriteriaInput] = useState({
    id: "",
    tag_name: "",
    color: "",
    weight: "",
    isDefaultID: "",
  });

  const weightOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ];

  const handleCancel = () => {
    setCriteriaPop(false);
    setCriteriaInput({
      id: "",
      isDefaultID: "",
      tag_name: "",
      color: "",
      weight: "",
    });
  };

  const handleSave = () => {
    setCriteriaPop(false);

    setCriteriaInput({
      tag_name: "",
      color: "",
      weight: "",
      id: "",
      isDefaultID: "",
    });

    if (isEdit) {
      const updatedCriteria = criteria_tags.map((item) =>
        item.id === criteriaInput.id
          ? {
              id: criteriaInput.id,
              tag_name: criteriaInput.tag_name,
              color: criteriaInput.color,
              weight: criteriaInput.weight,
              isDefaultID: criteriaInput.isDefaultID,
            }
          : item
      );

      setCriteriaTags(updatedCriteria);
      setIsEdit(false);
      return;
    }

    setCriteriaTags([
      ...criteria_tags,
      {
        id: criteria_tags.length + 1,
        tag_name: criteriaInput.tag_name,
        color: criteriaInput.color,
        weight: criteriaInput.weight,
      },
    ]);
  };

  const handleEdit = (id) => {
    const selectedTag = criteria_tags?.find((item) => item?.id === id);

    setCriteriaInput({
      id: selectedTag.id,
      tag_name: selectedTag.tag_name,
      color: selectedTag.color,
      weight: selectedTag.weight,
      isDefaultID: selectedTag.isDefaultID,
    });

    setIsEditData({
      id: selectedTag.id,
      tag_name: selectedTag.tag_name,
      color: selectedTag.color,
      weight: selectedTag.weight,
      isDefaultID: selectedTag.isDefaultID,
    });

    setIsEditId(id);
    setIsEdit(true);
  };

  const isDefaultId1 = criteria_tags?.find((item) => item?.isDefaultID === 1);
  const isDefaultId2 = criteria_tags?.find((item) => item?.isDefaultID === 2);

  console.log(criteriaInput, "400001");
  console.log(criteria_tags, "400002");

  return (
    <div className={styles.criteria}>
      <h2>Criteria Tags</h2>

      <div className={styles.criteria_head}>
        <ul>
          <li>Tag Name</li>
          <li>Weight</li>
          <li>Actions</li>
        </ul>
      </div>

      <div className={styles.criteria_body}>
        {criteria_tags?.map((item, i) => (
          <ul key={i}>
            <li className={styles.criteria_tag}>
              <div
                className={styles.criteria_tag_value}
                style={{
                  backgroundColor: item?.color,
                }}
              >
                {item?.tag_name}
              </div>
            </li>
            <li className={styles.criteria_value}>{item?.weight}</li>
            <li className={styles.criteria_action}>
              <Popover
                content={
                  <>
                    <div className={styles.criteria_popover}>
                      <div className={styles.criteria_popover_header}>
                        <h3>Edit Tag</h3>
                        <Close onClick={() => setIsEdit(false)} />
                      </div>

                      <div className={styles.criteria_popover_input}>
                        <label htmlFor="">Tag Name</label>
                        <input
                          type="text"
                          value={criteriaInput.tag_name}
                          onChange={(e) =>
                            setCriteriaInput({
                              ...criteriaInput,
                              tag_name: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className={styles.criteria_popover_color_section}>
                        <div className={styles.criteria_popover_hash}>#</div>
                        <div className={styles.criteria_popover_colorLists}>
                          {CriteriaColors.map((color, index) => (
                            <div
                              className={styles.criteria_popover_color}
                              key={index}
                              style={{
                                border: `${
                                  criteriaInput?.color?.toLowerCase() ===
                                  color?.toLowerCase()
                                    ? `1px solid ${color?.toLowerCase()}`
                                    : "1px solid #fff"
                                }`,
                              }}
                              onClick={() =>
                                setCriteriaInput({
                                  ...criteriaInput,
                                  color: color,
                                })
                              }
                            >
                              <div
                                className={styles.criteria_popover_color_cricle}
                                style={{
                                  backgroundColor: color?.toLowerCase(),
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className={styles.criteria_popover_input}>
                        <label htmlFor="">Weight</label>
                        <Select
                          size="large"
                          style={{
                            width: "100%",
                            border: "1px solid #E5E5E5",
                          }}
                          dropdownStyle={{ maxHeight: 200, overflowY: "auto" }}
                          suffixIcon={<ArrowDown />}
                          options={weightOptions}
                          value={criteriaInput.weight}
                          onChange={(value) =>
                            setCriteriaInput({
                              ...criteriaInput,
                              weight: value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className={styles.criteria_popover_actions}>
                      <button onClick={handleCancel}>Cancel</button>
                      <button
                        onClick={handleSave}
                        disabled={
                          !criteriaInput.tag_name ||
                          !criteriaInput.color ||
                          !criteriaInput.weight ||
                          (criteriaInput.tag_name === isEditData.tag_name &&
                            criteriaInput.color === isEditData.color &&
                            criteriaInput.weight === isEditData.weight)
                        }
                      >
                        Save
                      </button>
                    </div>
                  </>
                }
                trigger="click"
                getPopupContainer={(trigger) => trigger.parentElement}
                placement="bottomRight"
                overlayClassName={"criteria_popover"}
                open={isEditId === item?.id ? isEdit : false}
                onOpenChange={(value) => {
                  setIsEdit(value);
                  if (!value) {
                    setCriteriaInput({
                      id: "",
                      tag_name: "",
                      color: "",
                      weight: "",
                      isDefaultID: "",
                    });
                  }
                }}
              >
                <EditIcon
                  onClick={() => {
                    handleEdit(item?.id);
                  }}
                />
              </Popover>

              <TrashIcon
                onClick={() => {
                  setCriteriaTags(
                    criteria_tags.filter((tag) => tag.id !== item.id)
                  );
                }}
              />
            </li>
          </ul>
        ))}
      </div>

      <Popover
        content={
          <>
            <div className={styles.criteria_popover}>
              <div className={styles.criteria_popover_header}>
                <h3>Add Tag</h3>
                <Close onClick={() => setCriteriaPop(false)} />
              </div>

              <div className={styles.criteria_popover_input}>
                <label htmlFor="">Tag Name</label>
                <input
                  type="text"
                  value={criteriaInput.tag_name}
                  onChange={(e) =>
                    setCriteriaInput({
                      ...criteriaInput,
                      tag_name: e.target.value,
                    })
                  }
                />
              </div>

              <div className={styles.criteria_popover_color_section}>
                <div className={styles.criteria_popover_hash}>#</div>
                <div className={styles.criteria_popover_colorLists}>
                  {CriteriaColors.map((color, index) => (
                    <div
                      className={styles.criteria_popover_color}
                      key={index}
                      style={{
                        border: `${
                          criteriaInput?.color?.toLowerCase() ===
                          color?.toLowerCase()
                            ? `1px solid ${color?.toLowerCase()}`
                            : "1px solid #fff"
                        }`,
                      }}
                      onClick={() =>
                        setCriteriaInput({ ...criteriaInput, color: color })
                      }
                    >
                      <div
                        className={styles.criteria_popover_color_cricle}
                        style={{ backgroundColor: color?.toLowerCase() }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.criteria_popover_input}>
                <label htmlFor="">Weight</label>
                <Select
                  size="large"
                  style={{ width: "100%", border: "1px solid #E5E5E5" }}
                  suffixIcon={<ArrowDown />}
                  dropdownStyle={{ maxHeight: 200, overflowY: "auto" }}
                  options={weightOptions}
                  value={criteriaInput.weight}
                  onChange={(value) =>
                    setCriteriaInput({ ...criteriaInput, weight: value })
                  }
                />
              </div>
            </div>

            <div className={styles.criteria_popover_actions}>
              <button onClick={handleCancel}>Cancel</button>
              <button
                onClick={handleSave}
                disabled={
                  !criteriaInput.tag_name ||
                  !criteriaInput.color ||
                  !criteriaInput.weight
                }
              >
                Save
              </button>
            </div>
          </>
        }
        trigger="click"
        getPopupContainer={(trigger) => trigger.parentElement}
        placement="bottomRight"
        overlayClassName={"criteria_popover"}
        open={criteriaPop}
        onOpenChange={(value) => {
          setCriteriaPop(value);
          setIsEdit(false);
          setCriteriaInput({
            tag_name: "",
            color: "",
            weight: "",
            id: "",
            isDefaultID: "",
          });
        }}
      >
        <div className={styles.criteria_create}>
          <PlusBlue />
          Create tag
        </div>
      </Popover>

      <div className={styles.criteria_head}>
        <ul>
          <li>Default Tags</li>
          <li>Weight</li>
          <li>Actions</li>
        </ul>
      </div>

      <div className={styles.criteria_body}>
        {!isDefaultId1 && (
          <ul>
            <li className={styles.criteria_tag}>
              <div
                className={styles.criteria_tag_value}
                style={{
                  backgroundColor: "#2292E2",
                }}
              >
                Urgency
              </div>
            </li>
            <li className={styles.criteria_value}>
              <span>0</span>
            </li>
            <li
              className={styles.criteria_action}
              onClick={() =>
                setCriteriaTags([
                  ...criteria_tags,
                  {
                    id: criteria_tags.length + 1,
                    isDefaultID: 1,
                    tag_name: "Urgency",
                    color: "#2292E2",
                    weight: "0",
                  },
                ])
              }
            >
              <PlusBlue /> Add
            </li>
          </ul>
        )}

        {!isDefaultId2 && (
          <ul>
            <li className={styles.criteria_tag}>
              <div
                className={styles.criteria_tag_value}
                style={{
                  backgroundColor: "#EC7A1D",
                }}
              >
                Complexity
              </div>
            </li>
            <li className={styles.criteria_value}>
              <span>0</span>
            </li>
            <li
              className={styles.criteria_action}
              onClick={() =>
                setCriteriaTags([
                  ...criteria_tags,
                  {
                    id: criteria_tags.length + 1,
                    isDefaultID: 2,
                    tag_name: "Complexity",
                    color: "#EC7A1D",
                    weight: "0",
                  },
                ])
              }
            >
              <PlusBlue /> Add
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default CriteriaTag;
