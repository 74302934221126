import React from "react";
import styles from "./FormInput.module.scss";

const FormInput = ({
  label,
  error,
  placeholder,
  hint = "",
  name,
  value,
  options = [],
  setFieldValue,
  type = "input",
  height,
  generateWithAI,
  disabled,
  generateWithAIClick,
  customStyle,
  ...rest
}) => {
  return (
    <div className={styles.inputGroup}>
      {generateWithAI ? (
        <div className={styles.labelDiv}>
          <label className={styles.label} htmlFor={name}>
            {label}
            {hint && <span>{`(${hint})`}</span>}
          </label>
          <span
            onClick={generateWithAIClick}
            className={styles.generateWithAI}
          >{`Generate with AI`}</span>
        </div>
      ) : (
        <label className={styles.label} htmlFor={name}>
          {label}
          {hint && <span>{`(${hint})`}</span>}
        </label>
      )}
      {type !== "select" && type !== "textarea" && (
        <input
          style={{ height: height }}
          id={name}
          value={value}
          onChange={(e) => {
            if (setFieldValue !== undefined) {
              setFieldValue(name, e.target.value);
            }
          }}
          placeholder={placeholder}
          className={`${styles.formControl} ${error ? styles.error : ""}`}
          type={type}
          {...rest}
        />
      )}
      {type === "textarea" && (
        <textarea
          style={{ height: height }}
          id={name}
          value={value || ""}
          onChange={(e) => {
            if (setFieldValue !== undefined) {
              setFieldValue(name, e.target.value);
            }
          }}
          placeholder={placeholder}
          className={`${styles.formControl}  ${error ? styles.error : ""}`}
          type={type}
          {...rest}
        />
      )}
      {type === "select" && (
        <select
          style={{ ...customStyle, height }}
          id={name}
          value={value}
          onChange={(e) => {
            if (setFieldValue !== undefined) {
              setFieldValue(name, e.target.value);
            }
          }}
          placeholder={placeholder}
          className={`${styles.formControl} ${error ? styles.error : ""
            } custom-select`}
          required
          disabled={disabled}
          {...rest}
        >
          {options.map((option) => (
            <option value={option.value} selected={option?.disabled} disabled={option?.disabled} key={option.id || option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
      {error && <span className={styles.spanError}>{error}</span>}
    </div>
  );
};

export default FormInput;
