import { Col, DatePicker, Dropdown, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import classes from "./ActionItemsModal.module.scss";
import AppInput from "../../components/AppInput"

import { ReactComponent as CalanderIcon } from "assets/svg/calander.svg";
import { ReactComponent as ArrowDown } from "assets/svg/arrowDownGray.svg";
import { ReactComponent as Close } from "assets/svg/close.svg";

import Button from '../Button';
import { Select, Tag } from 'antd';
import moment from 'moment';
import { durationList } from '../../utils/constants';
import { ReactComponent as TodoIcon } from "./../../scenes/PlanDetails/ActionItems/Category//todo.svg";
import { ReactComponent as InProgessIcon } from "./../../scenes/PlanDetails/ActionItems/Category//inProgress.svg";
import { ReactComponent as OnHoldIcon } from "./../../scenes/PlanDetails/ActionItems/Category//onHold.svg";
import { ReactComponent as CompleteIcon } from "./../../scenes/PlanDetails/ActionItems/Category/complete.svg";
import { ReactComponent as CancelIcon } from "./../../scenes/PlanDetails/ActionItems/Category/canceled.svg";

import { useDispatch, useSelector } from 'react-redux';
import { getProjectUsers, getProjectList } from '../../modules/actions/UserActions';
import InviteUserModal from '../../scenes/PlanDetails/ActionItems/InviteUserModal';
import { createNewActionItemsModal } from '../../modules/actions/PlanActions';
export const ActionItemsModal = ({ actionItemsModal, setActionItemsModal, data, isName, projectPlanId, PlanDetails, allData }) => {
    const dispatch = useDispatch();

    const initialValues = {
        ActionItemName: allData.name,
        context: "",
        start_line: allData.start_line,
        dead_line: allData.dead_line,
        weight: allData.weight,
        start: allData.start,
        current: allData.current,
        target: allData.target,
        duration: allData.duration,
        plan_progress: allData.plan_progress,
        Priority: allData.priority,
        category: allData.category_name,
        Status: allData.status,
        Assignee: allData.responsible_person?.email,
        project_plan_name: allData?.project_plan_name,
        category_name: allData?.category_name,
        category: allData?.category,
        project_plan_id: allData?.project_plan_id
    };

    const [values, setValues] = useState(initialValues);

    const [hasChanged, setHasChanged] = useState(false);
    // Effect to check for changes
    useEffect(() => {
        // Check if current values are different from initial values
        const isChanged = Object.keys(initialValues).some(
            key => values[key] !== initialValues[key]
        );

        // Update `hasChanged` based on comparison
        setHasChanged(isChanged);
    }, [values]);

    // Update function example for controlled input
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };


    const onSubmit = () => {
        const payload = {
            action_item: {
                id: allData.id,
                name: values.ActionItemName,
                weight: values.weight,
                start: Number(values.start).toFixed(2),
                current: Number(values.current).toFixed(2),
                target: Number(values.target).toFixed(2),
                start_line: momentStartLine,
                dead_line: momentDeadLine,
                duration: values?.duration,
                responsible_person: assigneeID || allData?.responsible_person?.id,
                status: values?.Status,
                plan_progress: values?.plan_progress,
                priority: values?.Priority,
                project_plan: values?.project_plan_id,
                category: values?.category
            },
            note: {
                action_item: allData?.id,
                context: note
            }
        };

        dispatch(createNewActionItemsModal(payload, setActionItemsModal(), projectPlanId));
    };

    const [itemName, setItemName] = useState(isName || "");
    const handleActionItemChange = (_, value) => {
        setItemName(value);
    };
    const [note, setNote] = useState(null);

    useEffect(() => {
        setNote(data?.context || "");
        setItemName(isName)
    }, [data, isName]);
    const handleNoteItemChange = (_, value) => {
        setNote(value);
    };


    const criteria_tag = [
        { id: 32, name: "Impact", weight: 0, action_item: 63 },
        { id: 31, name: "Complexity", weight: 0, action_item: 63 },
        { id: 30, name: "Urgency", weight: 0, action_item: 63 },
        { id: 33, name: "Feasibility", weight: 0, action_item: 64 },
        { id: 34, name: "Sustainability", weight: 0, action_item: 65 }
    ];

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };



    const options = criteria_tag.map(test => ({
        label: test.name,
        value: test.id,

    }));

    const colorMap = {};


    const predefinedColors = ['#E9B803', '#E76A99', '#3357FF', '#FF33A1', '#FF8C33'];

    const getColorForTag = (value) => {
        if (!colorMap[value]) {
            const colorIndex = Object.keys(colorMap).length % predefinedColors.length;
            colorMap[value] = predefinedColors[colorIndex];
        }
        return colorMap[value];
    };

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;

        const color = getColorForTag(value);

        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                closable={closable}
                onClose={onClose}
                onMouseDown={onPreventMouseDown}
                style={{
                    marginInlineEnd: 4,
                    borderRadius: "20px",
                    backgroundColor: color,
                    color: '#fff',

                }}
            >
                {label}
            </Tag>
        );
    };

    const handleChnage = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const momentDeadLine = values?.dead_line
        ? moment(values.dead_line, "YYYY-MM-DD").format("YYYY-MM-DD")
        : null; // Or any other default value you prefer, such as "N/A"

    const momentStartLine = values?.start_line
        ? moment(values.start_line, "YYYY-MM-DD").format("YYYY-MM-DD")
        : null; // Or any other default value you prefer, such as "N/A"

    const [assignee, setAssignee] = useState(null);
    const [assigneeID, setAssigneeId] = useState(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [showInvite, setShowInvite] = useState(false);
    const [emailToInvite, setEmailToInvite] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
    const [category, setCategory] = useState([])

    useEffect(() => {
        dispatch(getProjectUsers());
        dispatch(getProjectList());
    }, [dispatch]);

    console.log('alldata', allData)

    const userName = assignee


    const { projectUser, projectList } = useSelector((state) => state.user.projectOwners);
    const selectedUser = projectUser.find(user => user?.id === assigneeID);

    const AssignOptions = projectUser?.map((user) => ({
        label: user.email,
        value: user.id,
        image: user?.profile_picture,
        initials: user.name ? user.name.match(/\b\w/g).join("") : user.email[0].toUpperCase(),
    }));

    const handleSearch = (value) => {
        setSearchTerm(value);


        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        const userExists = AssignOptions.some((option) => option.label && option.label.includes(value));

        setShowInvite(isEmailValid && !userExists);
    };

    const handleInviteClick = () => {
        setIsModalVisible(true);
        setEmailToInvite(searchTerm)

    };

    useEffect(() => {
        const project = projectList?.filter((item) => item.id == allData?.project_plan_id)
        if (project) {
            setCategory(project[0]?.action_categories)
        }

    }, [projectList])


    const closeModal = () => {
        setIsModalVisible(false);
        setEmailToInvite(null);
        setSearchTerm("");
        setShowInvite(false);
        dispatch(getProjectUsers());
    };

    return (
        <>

            <Modal
                centered
                open={actionItemsModal}

                bodyStyle={{ padding: "10px", backgroundColor: "#FAFAFA" }}
                closable={null}
                width={1053}
                footer={<div className='d-flex  gap-2 justify-content-end' style={{ backgroundColor: "#FAFAFA", padding: "10px 20px 10px 10px", margin: "-10px" }}>

                    <Button
                        style={{
                            backgroundColor: "transparent",
                            borderRadius: "2px",
                            color: "#1271A6",
                            border: "1px solid #1271A6",
                            width: "88px",
                        }}
                        onClick={() => setActionItemsModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => onSubmit()}
                        disabled={!hasChanged}
                        style={{ backgroundColor: "#1271A6", color: "white", width: "88px", border: "1px solid #1271A6", borderRadius: "2px", opacity: !hasChanged ? 0.5 : 1 }}
                    >
                        Save
                    </Button>



                </div>

                }
            >
                <div className={classes.wrapper}>
                    <div className={classes.header}>
                        <span >Action Item Details</span>
                        <button
                            type="button"
                            onClick={() => setActionItemsModal(false)}
                            className={classes.close}
                            title="Close"
                        >
                            X
                        </button>
                    </div>
                    <Row>
                        <Col md={18}
                            className={classes.LeftSide}
                        >
                            <label className={classes.label}>Action Item Name</label>
                            <AppInput
                                type="text"
                                className="input"
                                placeholder="Action Item Name"
                                value={values.ActionItemName}
                                onChange={(name, value) => {


                                    setValues({ ...values, ActionItemName: value });
                                }}
                            // value={allData?.name}
                            // onChange={handleActionItemChange}
                            />
                            <label className={classes.label}>Project</label>
                            <Select
                                dropdownMatchSelectWidth={false}
                                size="large"
                                placeholder="Plan Projects"
                                style={{
                                    width: "100%",
                                    border: "1px solid #E5E5E5",
                                }}
                                suffixIcon={<ArrowDown />}
                                value={values?.project_plan_name}
                                onChange={(value) => {
                                    const project = projectList?.find((item) => item.id == value);
                                    if (project) {
                                        setCategory(project?.action_categories);
                                    }
                                    setValues({ ...values, project_plan_name: project?.name, project_plan_id: value });
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {projectList.map((p, index) => (

                                    <Option key={index} value={p.id}>
                                        {p.name}
                                    </Option>
                                ))}
                            </Select>


                            <label className={classes.label}>Category</label>
                            <Select
                                dropdownMatchSelectWidth={false}
                                size="large"
                                placeholder="Category"
                                disabled={allData?.project_plan_name === 0}
                                style={{
                                    width: "100%",
                                    border: "1px solid #E5E5E5",
                                }}
                                suffixIcon={<ArrowDown />}
                                value={values?.category_name}
                                onChange={(value) => {
                                    const filterCategory = category?.find((item) => item.id === value);
                                    if (filterCategory) {
                                        setValues({ ...values, category_name: filterCategory.name, category: value });
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {category?.map((c, index) => (
                                    <Option key={index} value={c.id}>
                                        {c.name}
                                    </Option>
                                ))}
                            </Select>


                            {/* <AppInput
                                type="text"
                                className="input"
                                placeholder="Category"
                                disabled
                                value={values?.category_name}
                            // value={itemName}
                            // onChange={handleActionItemChange}
                            /> */}
                            <Row>
                                <Col md={8}>
                                    <div className={classes.box}>
                                        <label className={classes.label} htmlFor="">Start Date</label>
                                        <DatePicker
                                            size="large"
                                            allowClear
                                            placeholder="MM/DD/YYYY"
                                            className="antd-suffixIcon"
                                            inputReadOnly={true}
                                            suffixIcon={<CalanderIcon />}
                                            style={{ width: "100%" }}
                                            value={
                                                values?.start_line ? moment(values?.start_line) : null
                                            }
                                            disabledDate={(current) => {
                                                const startLine = moment(allData?.category_details?.start_line);
                                                const deadLine = moment(allData?.category_details?.dead_line);
                                                return current && (current < startLine || current > deadLine);
                                            }}
                                            onChange={(date, value) => {
                                                setValues({
                                                    ...values,
                                                    start_line: date
                                                        ? moment(date).format("YYYY-MM-DD")
                                                        : null,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className={classes.box}>
                                        <label className={classes.label} htmlFor="" >Due Date</label>
                                        <DatePicker
                                            size="large"
                                            allowClear
                                            placeholder="MM/DD/YYYY"
                                            className="antd-suffixIcon"
                                            inputReadOnly={true}
                                            suffixIcon={<CalanderIcon />}
                                            style={{ width: "100%" }}

                                            value={values?.dead_line ? moment(values?.dead_line) : null}
                                            disabledDate={(current) => {
                                                const startLine = moment(allData?.category_details?.start_line);
                                                const deadLine = moment(allData?.category_details?.dead_line);
                                                return current && (current < startLine || current > deadLine);
                                            }}
                                            onChange={(date, value) => {
                                                setValues({
                                                    ...values,
                                                    dead_line: date
                                                        ? moment(date).format("YYYY-MM-DD")
                                                        : null,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <label className={classes.label} htmlFor="">Weight</label>
                                    <Select
                                        dropdownMatchSelectWidth={false}
                                        size="large"
                                        placeholder="Weight"
                                        style={{
                                            width: "100%",
                                            border: "1px solid #E5E5E5",
                                        }}
                                        suffixIcon={<ArrowDown />}
                                        value={values.weight}
                                        onChange={(value) => {
                                            setValues({ ...values, weight: value });
                                        }}
                                    >
                                        {[
                                            { value: 1, label: "1" },
                                            { value: 2, label: "2" },
                                            { value: 3, label: "3" },
                                            { value: 4, label: "4" },
                                            { value: 5, label: "5" },
                                            { value: 6, label: "6" },
                                            { value: 7, label: "7" },
                                            { value: 8, label: "8" },
                                            { value: 9, label: "9" },
                                            { value: 10, label: "10" },].map((count, index) => (
                                                <Option key={index} value={count.value}>
                                                    {count.key}
                                                </Option>
                                            ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <div className={classes.box}>
                                        <label className={classes.label} htmlFor="">Start Value</label>
                                        <AppInput
                                            type="text"
                                            className="input"
                                            placeholder="Start Value"
                                            value={values.start}
                                            onChange={(name, value) => {


                                                setValues({ ...values, start: value });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className={classes.box}>
                                        <label className={classes.label} htmlFor="" >Current Value</label>
                                        <AppInput
                                            type="number"
                                            className="input"
                                            placeholder="Current Value"
                                            value={values.current}
                                            onChange={(name, value) => {
                                                setValues({ ...values, current: value });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <label className={classes.label} htmlFor="">Target Value</label>
                                    <AppInput
                                        type="number"
                                        className="input"
                                        placeholder="Target Value"
                                        value={values.target}
                                        onChange={(name, value) => {
                                            setValues({ ...values, target: value });
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <label className={classes.label} htmlFor="" >Criteria Tags</label>
                                    <div className={classes.box}>

                                        <Select
                                            mode="multiple"
                                            placeholder="Criteria Tags"
                                            tagRender={tagRender}
                                            defaultValue={[options[0].value, options[1].value]}
                                            style={{
                                                width: '100%',
                                                flexDirection: "row",
                                                flexWrap: "unset",
                                                padding: "5px",
                                                border: "1px solid #E5E5E5"
                                            }}
                                            options={options}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                        />

                                    </div>

                                </Col>

                                <Col md={8}>
                                    <div className={classes.box}>
                                        <label className={classes.label} htmlFor="" >Priority</label>

                                        <Select
                                            dropdownMatchSelectWidth={false}
                                            size="large"
                                            placeholder="Priority"
                                            style={{
                                                width: "100%",
                                                border: "1px solid #E5E5E5",
                                            }}
                                            suffixIcon={<ArrowDown />}
                                            value={values.Priority}
                                            onChange={(value) => {

                                                setValues({ ...values, Priority: value });
                                            }}
                                        >
                                            {[
                                                { value: 3, label: "High" },
                                                { value: 2, label: "Medium" },
                                                { value: 1, label: "Low" },].map((count, index) => (
                                                    <Option key={index} value={count.value}>
                                                        {count.label}

                                                    </Option>
                                                ))}
                                        </Select>
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <label className={classes.label} htmlFor="" style={{ backgroundColor: "#fffff" }}>Plan Progress</label>

                                    <Select
                                        dropdownMatchSelectWidth={false}
                                        size="large"
                                        placeholder="Plan Progress"
                                        style={{
                                            width: "100%",
                                            border: "1px solid #E5E5E5",
                                        }}
                                        suffixIcon={<ArrowDown />}
                                        value={values.plan_progress}
                                        onChange={(value) => {
                                            setValues({ ...values, plan_progress: value });
                                        }}
                                    >
                                        {[
                                            { value: 1, label: "1" },
                                            { value: 2, label: "2" },
                                            { value: 3, label: "3" },
                                            { value: 4, label: "4" },
                                            { value: 5, label: "5" },
                                            { value: 6, label: "6" },
                                            { value: 7, label: "7" },
                                            { value: 8, label: "8" },
                                            { value: 9, label: "9" },
                                            { value: 10, label: "10" },].map((count, index) => (
                                                <Option key={index} value={count.value}>
                                                    {count.label}
                                                </Option>
                                            ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <div className={classes.box}>
                                        <label className={classes.label} htmlFor="">Duration</label>
                                        <Select
                                            dropdownMatchSelectWidth={false}
                                            size="large"
                                            placeholder="-- --"
                                            style={{
                                                width: "100%",
                                                border: "1px solid #E5E5E5",
                                            }}
                                            suffixIcon={<ArrowDown />}
                                            value={values.duration}
                                            onChange={(value) => {
                                                setValues({ ...values, duration: value });
                                            }}
                                        >
                                            {durationList.map((count, index) => (
                                                <Option key={index} value={count.value}>
                                                    {count.key}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className={classes.box}>
                                        <label className={classes.label} htmlFor="" >Assignee</label>
                                        <Select
                                            size="large"
                                            placeholder="--"
                                            style={{ width: "100%", border: "1px solid #E5E5E5" }}
                                            // className="antd-assignee"
                                            value={selectedUser ? {
                                                key: selectedUser,
                                                label: (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {selectedUser?.profile_picture ? (
                                                            <img
                                                                src={selectedUser?.profile_picture}
                                                                alt={userName}
                                                                style={{ width: 24, height: 24, borderRadius: '50%', marginRight: 8 }}
                                                            />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    minWidth: 24,
                                                                    height: 24,
                                                                    borderRadius: '50%',
                                                                    backgroundColor: '#ccc',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    marginRight: 8,
                                                                }}
                                                            >
                                                                {selectedUser?.email[0]}
                                                            </div>
                                                        )}
                                                        <span>{selectedUser?.username || selectedUser?.first_name || selectedUser?.last_name || selectedUser?.email?.split('@')[0]}</span>
                                                    </div>
                                                ),
                                            } : {
                                                label: (
                                                    values?.Assignee ?

                                                        (<div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div
                                                                style={{
                                                                    minWidth: 24,
                                                                    height: 24,
                                                                    borderRadius: '50%',
                                                                    backgroundColor: '#ccc',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    marginRight: 8,
                                                                }}
                                                            >
                                                                {values?.Assignee[0]}
                                                            </div>
                                                            <span>{values?.Assignee?.split('@')[0]}</span>
                                                        </div>) : "--"

                                                )
                                            }

                                            }




                                            showSearch
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                            onSearch={handleSearch}

                                            // filterOption={(input, option) =>
                                            //     option.label.toLowerCase().includes(input.toLowerCase())
                                            //   }
                                            labelInValue
                                            onSelect={(value) => {
                                                setAssigneeId(value?.value);
                                                setAssignee(value?.label);
                                            }}
                                            notFoundContent={
                                                showInvite && (
                                                    <Button
                                                        type="primary"
                                                        onClick={handleInviteClick}
                                                        style={{ width: "100%" }}
                                                    >
                                                        Invite {searchTerm}
                                                    </Button>
                                                )
                                            }
                                            optionLabelProp="label"

                                        >
                                            {AssignOptions.map((option, index) => (
                                                <Select.Option key={index} value={option.value} label={option.label}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {option.image ? (
                                                            <img
                                                                src={option.image}
                                                                alt={option.label}
                                                                style={{ width: 24, height: 24, borderRadius: '50%', marginRight: 8 }}
                                                            />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: 24,
                                                                    height: 24,
                                                                    borderRadius: '50%',
                                                                    backgroundColor: '#ccc',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    marginRight: 8,
                                                                }}
                                                            >
                                                                {option.label ? option.label[0] : '-'}
                                                            </div>
                                                        )}
                                                        <span>{option.label}</span>
                                                    </div>
                                                </Select.Option>
                                            ))}
                                        </Select>





                                    </div>
                                </Col>
                                <Col md={8}>
                                    <label className={classes.label} htmlFor="">Status</label>

                                    <Select
                                        dropdownMatchSelectWidth={false}
                                        size="large"
                                        placeholder="Status"
                                        style={{
                                            width: "100%",
                                            border: "1px solid #E5E5E5",
                                        }}
                                        suffixIcon={<ArrowDown />}
                                        value={values.Status}
                                        onChange={(value) => {
                                            setValues({ ...values, Status: value });
                                        }}
                                    >
                                        <Select.Option value="" disabled>
                                            Select
                                        </Select.Option>
                                        <Select.Option value={1}>
                                            <TodoIcon style={{ marginRight: 8 }} />
                                            TODO
                                        </Select.Option>
                                        <Select.Option value={2}>
                                            <InProgessIcon style={{ marginRight: 8 }} />
                                            IN PROGRESS
                                        </Select.Option>
                                        <Select.Option value={3}>
                                            <OnHoldIcon style={{ marginRight: 8 }} />
                                            ON HOLD
                                        </Select.Option>
                                        <Select.Option value={4}>
                                            <CompleteIcon style={{ marginRight: 8 }} />
                                            COMPLETED
                                        </Select.Option>
                                        <Select.Option value={5}>
                                            <CancelIcon style={{ marginRight: 8 }} />
                                            CANCELED
                                        </Select.Option>
                                    </Select>



                                </Col>
                            </Row>
                            <label className={classes.label} htmlFor="">Notes</label>
                            <textarea
                                placeholder="Add notes"
                                value={note || ""}
                                onChange={(e) => {
                                    setNote(e?.target?.value)
                                    setHasChanged(true)
                                }}
                            >

                            </textarea>


                        </Col>
                        {/* <Col md={6}
                            className={classes.RightSide}
                        >
                            <div className={classes.headerRight}>

                                <h6 className={classes.activeHeading} >Comments</h6>
                                <h6 className={classes.unActiveHeading} style={{ marginLeft: "20px" }}>Activity</h6>


                            </div>
                            <div style={{ borderBottom: "1px solid #E5E5E5", padding: "10px" }} >
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className={classes.name}>you</label>
                                    <p className={classes.DateTime}>05.12.24, 10:10 pm</p>
                                </div>

                                <p className={classes.para}>Revolve around ensuring digital connectivity and inclusivity. With the increasing reliance on technology for communication, education, work, and various servi
                                    ces, bridging the digital divide has become essential.</p>
                            </div>

                        </Col> */}

                    </Row>
                </div>

            </Modal >
            {isModalVisible && (
                <InviteUserModal
                    isActive={isModalVisible}
                    emailToInvite={emailToInvite ?? ""}
                    onClose={closeModal}
                />)}
        </>
    )
}
